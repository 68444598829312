<template>
    <div v-if="localMBData.requestDone" class="basic">
        <div class="row">
            <div class="col-lg-6 mb-4">
                <LabelInput
                    class="mb-4"
                    font-family="'Oswald', sans-serif"
                    :base-card="true"
                    :label="labels.title"
                    :value="localMBData.title"
                    @changed="dataChanged({ value: $event, key: 'title' })"
                />
                <TextEditorCard
                    :title="labels.description"
                    :value="localMBData.description"
                    @changed="dataChanged({ value: $event.value, key: 'description' })"
                />
                <TextEditorCard
                    :title="labels.promises"
                    :value="localMBData.promises"
                    @changed="dataChanged({ value: $event.value, key: 'promises' })"
                />
                <TextEditorCard
                    :title="labels.target_audience"
                    :value="localMBData.targetAudience"
                    @changed="dataChanged({ value: $event.value, key: 'targetAudience' })"
                />
            </div>
            <div class="pricing col-lg-6 mb-4">
                <SelectableDropdown
                    v-if="features.payable"
                    class="mb-4"
                    :dropdown="dropdownData"
                    :checkbox="checkboxData"
                    @checkbox-changed="() => {}"
                    @dropdown-changed="() => {}"
                />
                <LabelInput
                    v-if="features.payable"
                    class="mb-4"
                    font-family="'Oswald', sans-serif"
                    :label="labels.price"
                    :base-card="true"
                    :value="localMBData.pricing && localMBData.pricing.price ? localMBData.pricing.price.toString() : '0'"
                    @changed="dataChanged({ value: $event, key: 'pricing.price' })"
                    type="number"
                />
                <AssignInput
                    :title="labels.groups"
                    :assign-list="localMBData.groups"
                    :show-add-btn="true"
                    :add-btn-label="labels.add"
                />
                <RadioInput
                    class="mt-4"
                    :options="statuses"
                    :label="labels.status"
                    :base-card="true"
                    :value="localMBData.status"
                    @changed="dataChanged({ value: parseInt( $event ), key: 'status' })"
                />
                <ArchiveCard :labels="labels" :title="localMBData.title" @archive="archiveMBundle" :type="labels['monetization_bundle']"/>
            </div>
        </div>
    </div>
</template>

<script>
import LabelInput from "@/components/Input/LabelInput";
import TextEditorCard from "@/components/Input/TextEditorCard";
import SelectableDropdown from "@/components/Dropdowns/SelectableDropdown";
import AssignInput from "@/components/Input/AssignInput";
import RadioInput from "@/components/Input/RadioInput";
import ArchiveCard from "@/components/Cards/ArchiveCard";

import { mapMutations, mapState, mapGetters, mapActions } from 'vuex';

export default {
    name: "Basic",
    components: {
        LabelInput,
        TextEditorCard,
        SelectableDropdown,
        AssignInput,
        RadioInput,
        ArchiveCard
    },
    data() {
        return {
            status: 0
        };
    },
    computed: {
        ...mapState( 'monetizationBundle', {
            singleData: 'singleData',
        }),
        dropdownData() {
            return {
                value: 'yearly',
                placeholder: this.labels.select_billing,
                label: "",
                list: { monthly: this.labels.monthly, yearly: this.labels.yearly }
            }
        },
        checkboxData() {
            return {
                value: "",
                placeholder: "",
                list: { free: this.labels.free, one_time: this.labels.one_time, recurring: this.labels.recurring }
            }
        },
        ...mapState( 'helper', ['features'] ),
        ...mapGetters('helper', ['labels']),
        localMBData() {
            return this.$store.state.monetizationBundle.localMBData;
        },
        ...mapState( 'monetizationBundle', ['localMBData'] ),
        statuses() {
            return {
                0: this.labels.coming_soon,
                1: this.labels.beta,
                2: this.labels.live
            };
        }
    },
    methods: {
		...mapMutations('monetizationBundle', ['updateLocalMBDataString']),
        ...mapActions('notification', ['addNotification']),
        dataChanged( data ) {
            this.updateLocalMBDataString({key: data.key, value: data.value});
            //this.$emit('bundleBasicChanged', data.key, data.value);
		},
        archiveMBundle() {
            this.$store.dispatch('monetizationBundle/archive', { id: this.$route.params.id }).then(res=>{
                if(res.success){
                    this.addNotification({
                        variant: "success",
                        msg: `${this.localMBData.title} ${this.labels.archived.toLowerCase()}`
                    });

                    this.$router.push({ name: 'monetization-bundle-overview'})
                } else {
                    this.addNotification({
                        variant: "danger",
                        msg: res.error,
                        labels: 'res.error'
                    });
                }
            })
        }
    }
};
</script>

<style lang="scss" scoped>
    ::v-deep {
        .formulate-input-element-decorator {
            margin-bottom: 0;
        }

        .text-editor-card {
            margin-bottom: 30px !important;

            &:last-child {
                margin-bottom: 0px !important;
            }
        }
    }
</style>
